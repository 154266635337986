<template>
    <ul class="nav navbar-nav">
        <li v-for="(item, i) in localItems" :class="classes(item)">
            <a v-if="item.children.length > 0" @click="toggleCollapse(item.id+'-dropdown-element')" style="cursor: pointer">
                <span :class="'icon '+item.icon_class"></span>
                <span class="title">{{ item.title }}</span>
            </a>
            <a v-else :href="item.href" style="cursor: pointer">
                <span :class="'icon '+item.icon_class"></span>
                <span class="title">{{ item.title }}</span>
            </a>
            <div v-if="item.children.length > 0" class="content" :id="item.id+'-dropdown-element'" :style="item.active ? '' : 'display: none'">
                <div class="panel-body">
                    <admin-menu :items="item.children"></admin-menu>
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
                default: [],
            }
        },
        data() {
            return {
                localItems: []
            }
        },
        created () {
            this.localItems =  [...this.items]
        },
        methods: {
            classes: function(item) {
                var classes = [];
                if (item.children.length > 0) {
                    classes.push('dropdown');
                }
                if (item.active) {
                    classes.push('active');
                }
                return classes.join(' ');
            },
            color: function(item) {
                if (item.color && item.color != '#000000') {
                    return item.color;
                }
                return '';
            },
            toggleCollapse(e) {
                let header = $(e);
                let content = '#' + e;
                $(content).slideToggle(500);
            }
        }
    };
</script>
