try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    require('bootstrap');
} catch (e) {
}

window.Vue = require('vue');
window.perfectScrollbar = require('perfect-scrollbar/jquery')($);

window.moment = require('moment-timezone');

require("flatpickr/dist/flatpickr.min");

window.toastr = require('toastr');
window.lodash_template = require("lodash.template");

require('jquery-match-height');
require('select2');
require('select2/dist/js/i18n/bn');

require('bootstrap-switch');
require('bootstrap4-toggle/js/bootstrap4-toggle.min.js');
window.helpers = require('./helpers.js');

Vue.component('admin-menu', require('./components/admin_menu.vue').default);

var admin_menu = new Vue({
    el: '#adminmenu',
});

window.formValidatorConfig = {
    errorElement: "em",
    onkeyup: false,
    errorPlacement: function (error, element) {
        error.addClass("help-block");
        element.parents(".form-group").addClass("has-feedback");

        if (element.prop("type") === "checkbox") {
            error.insertAfter(element.parent("label"));
        } else if (element.hasClass('select2') || element.hasClass('select2-ajax-custom') || element.hasClass('select2-ajax')) {
            error.insertAfter(element.parents(".form-group").find('.select2-container'));
        } else {
            error.insertAfter(element);
        }
    },
    highlight: function (element, errorClass, validClass) {
        $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        $(element).closest('.help-block').remove();
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
    }
};
window.asmProcessNumeric = function (number, isInt) {
    isInt = typeof isInt === 'undefined' ? false : isInt;
    let tmpNumber;
    if (isInt) {
        tmpNumber = parseInt(number, 10);
    } else {
        tmpNumber = parseFloat(number);
    }
    tmpNumber = isNaN(tmpNumber) ? 0 : tmpNumber;
    return tmpNumber;
}
window.formatMoney = function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}
let en2BnNumbersMapping = {
    '9': '৯',
    '8': '৮',
    '7': '৭',
    '6': '৬',
    '5': '৫',
    '4': '৪',
    '3': '৩',
    '2': '২',
    '1': '১',
    '0': '০'
};
let bnEnNumbersMapping = {
    '০': 0,
    '১': 1,
    '২': 2,
    '৩': 3,
    '৪': 4,
    '৫': 5,
    '৬': 6,
    '৭': 7,
    '৮': 8,
    '৯': 9
};

window.en2bnNumber = function (number) {
    if (typeof number !== 'string') {
        number = (number || '').toString();
    }

    let output = [];
    for (let i = 0; i < number.length; ++i) {
        if (en2BnNumbersMapping.hasOwnProperty(number[i])) {
            output.push(en2BnNumbersMapping[number[i]]);
        } else {
            output.push(number[i]);
        }
    }
    return output.join('');
}

window.bn2EnNumber = function (number) {
    if (typeof number !== 'string') {
        number = (number || '').toString();
    }

    let output = [];
    for (let i = 0; i < number.length; ++i) {
        if (bnEnNumbersMapping.hasOwnProperty(number[i])) {
            output.push(bnEnNumbersMapping[number[i]]);
        } else {
            output.push(number[i]);
        }
    }
    return output.join('');
}


jQuery.fn.tagName = function () {
    return this.prop("tagName");
};

$(document).ready(function () {
    $.ajaxSetup({
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
        }
    });

    $(".flat-date").each(function (key) {
        let format = $(this).data('format');
        $(this).flatpickr({
            altInput: true,
            altFormat: "j F, Y",
            dateFormat: format ? format : "Y-m-d",
        });
    });

    $(".flat-time").flatpickr({
        enableTime: true,
        noCalendar: true,
        altInput: true,
        altFormat: "h:i K",
        dateFormat: "H:i",
    });

    $(".flat-datetime").each(function (key) {
        let format = $(this).data('format');
        $(this).flatpickr({
            enableTime: true,
            altInput: true,
            altFormat: "j F, Y at h:i K",
            dateFormat: format ? format : "Y-m-d H:i",
        });
    });

    var appContainer = $(".app-container"),
        fadedOverlay = $('.fadetoblack'),
        hamburger = $('.hamburger');

    $('.side-menu').perfectScrollbar();

    $('#voyager-loader').fadeOut();

    $(".hamburger, .navbar-expand-toggle").on('click', function () {
        appContainer.toggleClass("expanded");
        $(this).toggleClass('is-active');
        if ($(this).hasClass('is-active')) {
            window.localStorage.setItem('voyager.stickySidebar', true);
        } else {
            window.localStorage.setItem('voyager.stickySidebar', false);
        }
    });

    $('select.select2').each(function () {
        let placeholder = $(this).data('placeholder');
        $(this).select2({
            width: '100%',
            placeholder: placeholder ? placeholder : 'নির্বাচন করুন',
            allowClear: true,
            language: 'bn',
        });

        $(this).on('select2:select', function (e) {
            let data = e.params.data;
            let dependentFields = $(this).data('dependent-fields');
            const formElem = $(this).closest('form');
            const extractType = $(this).data('extract-type');

            if (dependentFields && Array.isArray(dependentFields)) {
                dependentFields.forEach(function (itemVal) {
                    let elem;
                    if (extractType && extractType === 'id') {
                        elem = $("#" + itemVal);
                    } else {
                        elem = formElem.find("[name='" + itemVal + "']");
                    }

                    if (elem && elem.length > 0) {
                        elem.val(null).trigger('change');
                        if (typeof elem.valid === "function") {
                            elem.valid();
                        }
                    }
                });
            }
            let currTargetElem = $(e.currentTarget);
            currTargetElem.find("option[value='" + data.id + "']").attr('selected', 'selected');

            if (typeof currTargetElem.valid === 'function') {
                currTargetElem.valid();
            }

        });

        $(this).on('select2:unselect', function (e) {
            let data = e.params.data;
            const currentTarElem = $(e.currentTarget);
            currentTarElem.find("option[value='" + data.id + "']").attr('selected', false);
            if (typeof currentTarElem.valid === "function") {
                currentTarElem.valid();
            }
        });
    });

    $('select.select2-ajax').each(function () {
        let placeholder = $(this).data('placeholder');
        $(this).select2({
            width: '100%',
            placeholder: placeholder ? placeholder : 'নির্বাচন করুন',
            allowClear: true,
            language: 'bn',
            ajax: {
                url: $(this).data('get-items-route'),
                data: function (params) {
                    let dependOn = $(this).data('depend-on');
                    let filterConditions = [];
                    if (dependOn && Array.isArray(dependOn)) {
                        filterConditions = dependOn.map(function (itemVal) {
                            return $("[name='" + itemVal + "']");
                        }).filter(function (elements) {
                            return elements.length > 0 && elements.val() && elements.val().length > 0;
                        }).map(function (element) {
                            return {field: element.prop('name'), value: element.val()};
                        });
                    } else if (dependOn && typeof dependOn === 'object') {
                        $.each(dependOn, function (key, item) {
                            let element = $("[name='" + key + "']");
                            if (element.length > 0 && element.val())
                                filterConditions.push({value: element.val(), relation: item});
                        });
                    }

                    let additionalQueries = $(this).data('additional-queries');

                    if (additionalQueries && typeof additionalQueries === 'object') {
                        $.each(additionalQueries, function (key, val) {
                            if (key && val) filterConditions.push({field: key, value: val})
                        });
                    }

                    let query = {
                        search: params.term,
                        type: $(this).data('get-items-field'),
                        method: $(this).data('method'),
                        page: params.page || 1
                    };

                    let eloScope = $(this).data('scope');
                    if (eloScope) {
                        query['scope'] = eloScope;
                    }
                    if (filterConditions.length > 0) query['filter_condition'] = filterConditions;
                    return query;
                }
            }
        });

        $(this).on('select2:select', function (e) {
            let data = e.params.data;
            let dependentFields = $(this).data('dependent-fields');

            if (dependentFields && Array.isArray(dependentFields)) {
                dependentFields.forEach(function (itemVal) {
                    let elem = $("[name='" + itemVal + "']");
                    if (elem.length > 0) {
                        elem.val(null).trigger('change');
                        if (typeof elem.valid === "function") {
                            elem.valid();
                        }
                    }
                });
            }
            let currTargetElem = $(e.currentTarget);
            currTargetElem.find("option[value='" + data.id + "']").attr('selected', 'selected');
            if (typeof currTargetElem.valid === 'function') {
                currTargetElem.valid();
            }
        });

        $(this).on('select2:unselect', function (e) {
            let data = e.params.data;
            let currTargetElem = $(e.currentTarget);
            currTargetElem.find("option[value='" + data.id + "']").attr('selected', false);
            if (typeof currTargetElem.valid === 'function') {
                currTargetElem.valid();
            }
        });
    });


    $('select.select2-taggable').select2({
        width: '100%',
        tags: true,
        createTag: function (params) {
            let term = $.trim(params.term);
            return term === '' ? null : {
                id: term,
                text: term,
                newTag: true
            };
        }
    }).on('select2:selecting', function (e) {
        let $el = $(this);
        let route = $el.data('route');
        let label = $el.data('label');
        let errorMessage = $el.data('error-message');
        let newTag = e.params.args.data.newTag;
        if (!newTag) return;

        $el.select2('close');

        $.post(route, {[label]: e.params.args.data.text,})
            .done(function (data) {
                let newOption = new Option(e.params.args.data.text, data.data.id, false, true);
                $el.append(newOption).trigger('change');
            }).fail(function (error) {
            toastr.error(errorMessage);
        });

        return false;
    });

    $('.match-height').matchHeight();

    $(".side-menu .nav .dropdown").on('show.bs.collapse', function () {
        return $(".side-menu .nav .dropdown .collapse").collapse('hide');
    });

    $('.panel-collapse').on('hide.bs.collapse', function (e) {
        var target = $(e.target);
        if (!target.is('a')) {
            target = target.parent();
        }
        if (!target.hasClass('collapsed')) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
    });

    $(document).on('click', '.panel-heading a.panel-action[data-toggle="panel-collapse"]', function (e) {
        e.preventDefault();
        let $this = $(this);
        // Toggle Collapse
        $this.parents('.panel').find('.panel-body').slideToggle();
        $this.toggleClass('panel-collapsed');
        $this.toggleClass('voyager-angle-up voyager-angle-down');
    });

    //Toggle fullscreen
    $(document).on('click', '.panel-heading a.panel-action[data-toggle="panel-fullscreen"]', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.removeClass('voyager-resize-small voyager-resize-full');
        $this.closest('.panel').toggleClass('is-fullscreen');
    });

    // Save shortcut
    $(document).keydown(function (e) {
        if ((e.metaKey || e.ctrlKey) && e.keyCode == 83) { /*ctrl+s or command+s*/
            $(".btn.save").click();
            e.preventDefault();
            return false;
        }
    });

    /** Added by Mahmud */
    $('select.select2-ajax-custom').each(function () {
        let placeholder = $(this).data('placeholder');
        $(this).select2({
            width: '100%',
            placeholder: placeholder ? placeholder : 'নির্বাচন করুন',
            allowClear: true,
            language: 'bn',
            ajax: {
                url: $(this).data('url'),
                method: 'post',
                data: function (params) {
                    const formElem = $(this).closest('form');

                    const filterConditions = {};
                    const model = $(this).data('model');
                    const extractType = $(this).data('extract-type');

                    if (!model) return {};

                    let query = {
                        model: model,
                        page: params.page || 1,
                        type: 'select2'
                    };

                    if (params.term) {
                        query['search'] = params.term;
                    }

                    const dependOn = $(this).data('depend-on');

                    if (dependOn && Array.isArray(dependOn)) {
                        dependOn.map(function (itemVal) {
                            if (extractType && extractType === 'id') {
                                return formElem.find("#" + itemVal);
                            }
                            return formElem.find("[name='" + itemVal + "']");
                        }).filter(function (elements) {
                            return elements.length > 0 && elements.val() && elements.val().length > 0;
                        }).forEach(function (element) {
                            let propertyName;
                            if (extractType && extractType === 'id') {
                                propertyName = element.prop('id');
                            } else {
                                propertyName = element.prop('name');
                            }
                            filterConditions[propertyName] = element.val();
                        });
                    } else if (dependOn && typeof dependOn === 'object') {
                        $.each(dependOn, function (key, dbFieldName) {
                            let element;
                            if (extractType && extractType === 'id') {
                                element = formElem.find("#" + key);
                            } else {
                                element = formElem.find("[name='" + key + "']");
                            }

                            if (element.length > 0 && element.val().length > 0) {
                                filterConditions[dbFieldName] = element.val();
                            }
                        });
                    }

                    if (dependOn && Object.keys(filterConditions).length === 0) return false;

                    let relation = $(this).data('relation');
                    let relationDependOn = $(this).data('relation-depend-on');
                    let relationFilterConditions = {};

                    if (relation && relationDependOn && typeof relationDependOn === 'object') {
                        $.each(relationDependOn, function (key, dbFieldName) {
                            let element;
                            if (extractType && extractType === 'id') {
                                element = formElem.find("#" + key);
                            } else {
                                element = formElem.find("[name='" + key + "']");
                            }

                            if (element.length > 0 && element.val() && element.val().length > 0) {
                                relationFilterConditions[dbFieldName] = element.val();
                            }
                        });
                    }

                    let additionalQueries = $(this).data('additional-queries');

                    if (additionalQueries && typeof additionalQueries === 'object') {
                        $.each(additionalQueries, function (key, val) {
                            if (key && val) filterConditions[key] = val;
                        });
                    }

                    if (Object.keys(filterConditions).length > 0) {
                        query['filters'] = filterConditions;
                    }
                    if (Object.keys(relationFilterConditions).length > 0) {
                        query['relation_filters'] = relationFilterConditions;
                        query['relation'] = relation;
                    }
                    let label = $(this).data('label');
                    if (label) query['label'] = label;
                    let key = $(this).data('key');
                    if (key) query['key'] = key;

                    let additionalLabels = $(this).data('additional-labels');
                    if (additionalLabels && Array.isArray(additionalLabels) && additionalLabels.length) {
                        query['additional_fields'] = additionalLabels;
                    }

                    let dbConnection = $(this).data('connection');
                    if (dbConnection && Array.isArray(dbConnection) && dbConnection.length && ['master', 'district'].includes(dbConnection)) {
                        query['connection'] = dbConnection;
                    }

                    let searchFields = $(this).data('search-fields');
                    if (searchFields && Array.isArray(searchFields) && searchFields.length) {
                        query['search_fields'] = searchFields;
                    }

                    let eloScope = $(this).data('scope');
                    if (eloScope) {
                        query['scope'] = eloScope;
                    }
                    return query;
                }
            }
        });

        $(this).on('select2:select', function (e) {
            let data = e.params.data;
            const dependentFields = $(this).data('dependent-fields');
            const formElem = $(this).closest('form');
            const extractType = $(this).data('extract-type');

            if (dependentFields && Array.isArray(dependentFields)) {
                dependentFields.forEach(function (itemVal) {
                    let elem;
                    if (extractType && extractType === 'id') {
                        elem = $("#" + itemVal);
                    } else {
                        elem = formElem.find("[name='" + itemVal + "']");
                    }

                    if (elem && elem.length > 0) {
                        elem.val(null).trigger('change');
                        if (typeof elem.valid === "function") {
                            elem.valid();
                        }
                    }
                });
            }
            let currTargetElem = $(e.currentTarget);
            currTargetElem.find("option[value='" + data.id + "']").attr('selected', 'selected');

            if (typeof currTargetElem.valid === 'function') {
                currTargetElem.valid();
            }

        });

        $(this).on('select2:unselect', function (e) {
            var data = e.params.data;
            let currTargetElem = $(e.currentTarget);
            currTargetElem.find("option[value='" + data.id + "']").attr('selected', false);
            if (typeof currTargetElem.valid === 'function') {
                currTargetElem.valid();
            }
        });
    });


});
